// colours
.b-c-primary {
  background-color: $primary-color;
}
.b-c-secondary {
  background-color: $secondary-color;
}
.b-c-white {
  background-color: $white;
}
.b-c-light-gray {
  background-color: $light-gray;
}
.b-c-medium-gray {
  background-color: $medium-gray;
}
.b-c-charcol {
  background-color: $charcol;
}
.b-c-black {
  background-color: $black;
}

.f-c-black {
  color: $black;
}

// tagline
.tagline-img {
  @include breakpoint(small) {
    max-width: rem-calc(280);
  }
  @include breakpoint(medium) {
    max-width: rem-calc(368);
  }
}

// programme item
.programme-item {
  position: relative;
  .programme-item-bg {
    @include vertical-center();
    background-color: $primary-color;
    mix-blend-mode: multiply;
    left: 0;
    width: 100%;
    height: 100%;    
    z-index: 0;
    @include breakpoint(small) {
      max-height: rem-calc(200);
    }
    @include breakpoint(medium) {
      max-height: rem-calc(240);
    }
    @include breakpoint(large) {
      max-height: rem-calc(220);
    }
    &.bg-test {
      mix-blend-mode: normal;
    }
  }
  .programme-item-person {
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    @include breakpoint(small) {
      height: rem-calc(350);
      img {
        display: none;
      }
    }
    @include breakpoint(medium) {
      height: rem-calc(420);
    }
    @include breakpoint(large) {
      height: auto;
      img {
        display: block;
      }
    }
    .splatter {
      height:100%;
      left: 0;
      max-height: rem-calc(380);
      max-width: rem-calc(380);
      position: absolute;
      top: 0;      
      transform: rotate(90deg);
      width:100%;
      z-index: -1;
    }
  }
  .programme-item-content {
    @include vertical-center();
    padding: rem-calc(64) rem-calc(16);
    text-align: center;
    width: 100%;

    h2 {
      color: $white;
      display: inline-block;
      font-family: "rig-shaded-medium-face";
      font-weight: 400;
      padding: rem-calc(5) rem-calc(5) 0 rem-calc(5); 
      position: relative;
      @include breakpoint(small) {
        font-size: rem-calc(30);
        line-height: rem-calc(40);
        margin-bottom: rem-calc(16);
      }
      @include breakpoint(large) {
        font-size: rem-calc(20);
        line-height: rem-calc(24);
        margin-bottom: rem-calc(16);
      }
      @include breakpoint(xlarge) {
        font-size: rem-calc(24);
        line-height: rem-calc(28);
        margin-bottom: rem-calc(16);
      }
    }
    .btn {
      display: block;
      position: relative;
      margin: 0 auto;
      max-width: rem-calc(174);
      @include breakpoint(small) {
        font-size: rem-calc(16);
        line-height: rem-calc(16);
      }
      @include breakpoint(medium) {
        font-size: rem-calc(12);
        line-height: rem-calc(16);
      }
    }
  }
  &.programme-item-internships {
    .programme-item-bg {
      background-color: $internships;
    }
    .programme-item-person {
      .splatter {
        background-image: url("/assets/images/splatter-internships.png");
        background-repeat: no-repeat;
        background-size: contain;
        @include breakpoint(small) {          
          left: auto; 
          right: rem-calc(-100); 
          top: rem-calc(-32);      
        }
        @include breakpoint(large) {
          left: 0;
          right: auto; 
          top: 0;       
        }
      }
    }
    .programme-item-content {
      h2 {
        background-color: $internships;
      }
      .btn {
        &:hover {
          background-color: $internships;
          border-color: $white;
        }
      }
    }
  }
  &.programme-item-graduates {
    @include breakpoint(small) {
      margin-top: rem-calc(-32);        
    }
    @include breakpoint(medium) {
      margin-top: rem-calc(-60);         
    }
    @include breakpoint(large) {
      margin-top: 0; 
    }
    .programme-item-bg {
      background-color: $graduates;
    }
    .programme-item-person {
      .splatter {
        background-image: url("/assets/images/splatter-graduates.png");
        background-repeat: no-repeat;
        background-size: contain;
        @include breakpoint(small) { 
          top: rem-calc(-32);      
        }
        @include breakpoint(large) {
          top: 0;       
        }
      }
    }
    .programme-item-content {
      h2 {
        background-color: $graduates;
      }
      .btn {
        &:hover {
          background-color: $graduates;
          border-color: $white;
        }
      }
    }
  }
  &.programme-item-apprenticeships {
    @include breakpoint(small) {
      margin-top: rem-calc(-32);       
    }
    @include breakpoint(medium) {
      margin-top: rem-calc(-60);          
    }
    @include breakpoint(large) {
      margin-top: 0; 
    }
    .programme-item-bg {
      background-color: $apprenticeships;
    }
    .programme-item-person {
      .splatter {
        background-image: url("/assets/images/splatter-apprenticeships.png");
        background-repeat: no-repeat;
        background-size: contain;
        @include breakpoint(small) {          
          left: auto; 
          right: rem-calc(-100); 
          top: rem-calc(-32);      
        }
        @include breakpoint(large) {
          left: 0;
          right: auto; 
          top: 0;       
        }
      }
    }
    .programme-item-content {
      h2 {
        background-color: $apprenticeships;
      }
      .btn {
        &:hover {
          background-color: $apprenticeships;
          border-color: $white;
        }
      }
    }
  }
  &.programme-item-other {
    @include breakpoint(small) {
      margin-top: rem-calc(-32);       
    }
    @include breakpoint(medium) {
      margin-top: rem-calc(-60);          
    }
    @include breakpoint(large) {
      margin-top: 0; 
    }
    .programme-item-bg {
      background-color: $other;
    }
    .programme-item-person {
      .splatter {
        background-image: url("/assets/images/splatter-other.png");
        background-repeat: no-repeat;
        background-size: contain;
        @include breakpoint(small) {          
          left: auto; 
          right: rem-calc(-100); 
          top: rem-calc(-32);      
        }
        @include breakpoint(large) {
          left: 0;
          right: auto; 
          top: 0;       
        }
      }
    }
    .programme-item-content {
      h2 {
        background-color: $other;
      }
      .btn {
        &:hover {
          background-color: $other;
          border-color: $white;
        }
      }
    }
  }
  &:nth-of-type(odd){
    .programme-item-person {
      @include breakpoint(small) {
        background-position: right center;        
      }
      @include breakpoint(large) {
        background-position: center;
      }    
    }
  }
  &:nth-of-type(even){
    .programme-item-person {
      @include breakpoint(small) {
        background-position: left center;        
      }
      @include breakpoint(large) {
        background-position: center;
      }    
    }
  }
}

// intro img
.intro-img {
  @include breakpoint(small) {
    text-align: right;
    padding: rem-calc(50) 0;
  }
  @include breakpoint(large) {
    text-align: center;
    padding: 0;
  }

  // programme-person
  .programme-person {
    left: 0;
    max-width: rem-calc(520);
    position: absolute;
    z-index: 0;
    &.programme-person-internships {
      @include breakpoint(small) {
        left: rem-calc(-120);
        top: rem-calc(-40);
      }
      @include breakpoint(large) {
        left: -22%;
        top: rem-calc(-80);
      }
    }
    &.programme-person-graduates {
      @include breakpoint(small) {
        left: rem-calc(-60);
        top: rem-calc(-40);
      }
      @include breakpoint(large) {
        left: -16%;
        top: rem-calc(-48);
      }
    }
    &.programme-person-apprenticeships {
      @include breakpoint(small) {
        left: rem-calc(-130);
        top: rem-calc(-10);
      }
      @include breakpoint(large) {
        left: -20%;
        top: rem-calc(-50);
      }
    }
    &.programme-person-other {
      @include breakpoint(small) {
        left: rem-calc(-60);
        top: rem-calc(-40);
      }
      @include breakpoint(large) {
        left: -16%;
        top: rem-calc(-48);
      }
    }
  }
  &.policy-img {
    .programme-person {
      &.programme-person-internships {
        @include breakpoint(small) {
          left: rem-calc(-120);
          top: rem-calc(-40);
        }
        @include breakpoint(large) {
          left: -18%;
          top: rem-calc(-80);
        }
      }
      &.programme-person-graduates {
        @include breakpoint(small) {
          left: rem-calc(-60);
          top: rem-calc(-40);
        }
        @include breakpoint(large) {
          left: -5%;
          top: rem-calc(-48);
        }
      }
      &.programme-person-apprenticeships {
        @include breakpoint(small) {
          left: rem-calc(-130);
          top: rem-calc(-10);
        }
        @include breakpoint(large) {
          left: -20%;
          top: rem-calc(-50);
        }
      }
    }

  }

  // eic img
  .eic-img {
    position: relative;
    @include breakpoint(small) {
      max-width: rem-calc(180);
    }
    @include breakpoint(medium) {
      max-width: rem-calc(332);
    }
    @include breakpoint(large) {
      max-width: rem-calc(240);
      position: absolute;
      right: 0;
      top: 0;
    }
    @include breakpoint(xlarge) {
      max-width: rem-calc(332);
    }
  }
}

// page splatter
.page-splatter {
  background-position: right center;  
  background-repeat: no-repeat;
  background-size: contain;
  height: rem-calc(960);
  position: absolute;
  right: -20%;
  top: 0;
  width: 100%;
  z-index: -1;
  @include breakpoint(small) {          
    height: 100%;   
  }
  @include breakpoint(large) {          
    height: rem-calc(960);     
  }
  &.programme-person-internships {
    background-image: url("/assets/images/splatter-internships.png");
  }
  &.programme-person-graduates {
    background-image: url("/assets/images/splatter-graduates.png");
  }
  &.programme-person-apprenticeships {
    background-image: url("/assets/images/splatter-apprenticeships.png");
  }
  &.programme-person-other {
    background-image: url("/assets/images/splatter-other.png");
  }
}

// role item
.role-item {
  border-radius: rem-calc(10);
  border: solid rem-calc(1) $primary-color;
  margin-bottom: rem-calc(30);
  overflow: hidden;
  position: relative;

  .role-top {
    background-color: $white;
    padding: rem-calc(20) rem-calc(20) rem-calc(4) rem-calc(20);
    position: relative;
    h2 {
      margin-bottom: rem-calc(32);
    }
    .splatter {
      background-position: center;  
      background-repeat: no-repeat;
      background-size: cover;
      height: 100%;
      opacity: 0.3;
      position: absolute;
      right: -10%;
      top: -10%;
      transform: rotate(90deg);
      width: 50%;
    }
  }
  .role-bottom {
    background-color: $primary-color;    
    @include breakpoint(small) {          
      padding: rem-calc(20) rem-calc(20) rem-calc(64) rem-calc(20);
    }
    @include breakpoint(large) {          
      padding: rem-calc(20) rem-calc(20) rem-calc(140) rem-calc(20);
    }
    .cell-relative {
      padding-bottom: rem-calc(60);
    }
    p {
      color: $white;
    }
    .btn-text {
      margin: 0 auto rem-calc(16) auto;      
      text-align: center;
      span {
        display: block;
        font-weight: bold;
        margin-top: rem-calc(10);
      }
      @include breakpoint(small) {          
        position: relative; 
      }
      @include breakpoint(large) {     
        bottom: rem-calc(60);
        left: rem-calc(5); 
        position: absolute; 
        right: rem-calc(5);
      }
    }
    .btn {
      bottom: rem-calc(20);
      left:0;
      margin: 0 auto;
      max-width: rem-calc(250);
      position: absolute;
      right:0;
    }
    .role-msg {
      bottom: rem-calc(32);
      font-weight: bold;
      left: rem-calc(16);
      margin: 0 auto;
      position: absolute;
      right: rem-calc(16);
      text-align: center;
    }
    &.role-temp-btn {
      padding: rem-calc(20)!important;
      text-align: center;
      .btn {
        position: relative;
        display: inline-block;
        bottom: initial;
      }
    }
  }
  &.role-internships {
    background-color: $internships;
    border-color: $internships;    
    .role-top {
      h3 {
        color: $internships;
      }
      .splatter {
        background-image: url("/assets/images/splatter-internships.png");
      }
    }
    .role-bottom {
      background-color: $internships;
      padding: rem-calc(20) rem-calc(20) rem-calc(80) rem-calc(20);
    }
  }
  &.role-graduates {
    background-color: $graduates;
    border-color: $graduates;    
    .role-top {
      h3 {
        color: $graduates;
      }
      .splatter {
        background-image: url("/assets/images/splatter-graduates.png");
      }
    }
    .role-bottom {
      background-color: $graduates;
    }
  }
  &.role-apprenticeships {
    background-color: $apprenticeships;
    border-color: $apprenticeships;
    .role-top {
      h3 {
        color: $apprenticeships;
      }
      .splatter {
        background-image: url("/assets/images/splatter-apprenticeships.png");
      }
    }
    .role-bottom {
      background-color: $apprenticeships;
    }
  }
  &.role-other {
    background-color: $other;
    border-color: $other;    
    .role-top {
      h3 {
        color: $other;
      }
      .splatter {
        background-image: url("/assets/images/splatter-other.png");
      }
    }
    .role-bottom {
      background-color: $other;
    }
  }
}

// content bg
.content-bg {
  background-color: $white;
  @include breakpoint(small){
    padding: rem-calc(32) 0;
  }
}

.responsive-embed {
  margin-bottom: 0;
  padding-bottom: 58%;
  &.vertical-embed {
    max-width: 56.666%;
    padding-bottom: 100%;
  }
}

// content table
table {
  width: 100% !important;
  margin-bottom: rem-calc(20);
  th {
    background-color: $primary-color;
    color: $white;
    p {
      color: $white;
    }
  }
  th,
  td {
    text-align: center;
    padding: rem-calc(10);
    text-align: center;
    border: 2px solid $light-gray;
    width: 50% !important;
    height: auto !important;
    font-size: rem-calc(14);
    p {
      margin: 0;
    }
  }
}

.policy-table {
  border: 1px solid #ccc;
  border-collapse: collapse;
  margin: 0;
  padding: 0;
  width: 100% !important;
  height: auto !important;
  table-layout: fixed;

  .top-row {
    background-color: $primary-color;
    color: $white;
  }
  h2,
  h3 {
    margin: 0;
    font-size: 16px;
  }
  p {
    font-size: 14px;
    margin: 0;
  }
  tr {
    background-color: #f8f8f8;
    border: 1px solid #ddd;
    padding: 0.35em;
    width: auto !important;
    height: auto !important;
  }
  th,
  td {
    padding: 0.625em;
    text-align: center;
    border: 2px solid $white;
    width: auto !important;
    height: auto !important;
    font-size: 14px;
  }
  th {
    letter-spacing: 0.1em;
    text-transform: uppercase;
  }
  @include breakpoint(small only) {
    border: 0;
    thead {
      border: none;
      clip: rect(0 0 0 0);
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      width: 1px;
    }
    tr {
      border-bottom: 3px solid #ddd;
      display: block;
      margin-bottom: 0.625em;
    }
    td {
      border: none;
      border-bottom: 1px solid #ddd;
      display: block;
      text-align: right;
      &:before {
        content: attr(data-label);
        float: left;
        font-weight: bold;
        text-transform: uppercase;
      }
      &:last-child {
        border-bottom: 0;
      }
    }
  }
}

// breadcrumbs
.breadcrumbs {
  position: relative;
  padding: rem-calc(32) 0;
  &:before {
    background-color: $charcol;
    bottom: 0;
    content: "";
    display: block;
    height: 1px;
    left: 0;
    position: absolute;
    width: 100vw;
  }
  .separator {
    display: inline-block;
    padding: 0 rem-calc(10);
  }
  .breadcrumb-current {
    font-weight: 500;
  }
}

// policy modal
.reveal-policy {
  .reveal-content {
    padding: rem-calc(50);
    .btn {
      max-width: rem-calc(250);
    }
  }
}

// accessibiliity form
#access-form {
  max-height: 100% !important;
  width: 100%;
  @include breakpoint(small) {
    height: rem-calc(1000);
    margin-top: rem-calc(32);
  }
  @include breakpoint(medium) {
    height: rem-calc(1200);
    margin-top: rem-calc(48);
  }
}

// temp bg
.temp-bg {
  background-image: url("/assets/images/temp-bg.jpg");
  background-repeat: repeat;
  background-size: contain;
}
