/* Header */
.header{
	//border-bottom: rem-calc(10) solid $primary-color;
	padding: rem-calc(10) 0;
	position: relative;		
	//transition: padding-top 0.25s ease-in-out;
	width: 100%;
	z-index: 10;

	// logo
	.logo-container {
		position: relative;
		z-index: 0;
		.logo{
			display: block;	
			font-size: 0;
			position: relative;
			z-index: 9;
			@include breakpoint(small){
				max-width: rem-calc(132);
			}
			@include breakpoint(medium){
				max-width: rem-calc(170);
			}		
			svg {
				.logo-main {
					fill: $primary-color;
				}
			}	
			&:focus {
				svg{
					.svg-fill {
						fill: $black;
					}
				}
			}
		}
	}


	// nav container
	.nav-menu-container {
		@include breakpoint(small){
			background-color: $white;
			display: none;
			min-height:100vh;
			right:0;
			position: absolute;
			top:0;
			transition: height 0.25s ease;			
			width:66vw;
			z-index: 10;			
		}
		@include breakpoint(medium){			
			width: 50vw;			
		}
		@include breakpoint(large){
			background-color: transparent;
			display: block;
			height: auto;
			min-height: auto;
			right: initial;
			position: relative;
			top: initial;			
			width: auto;	
		}
		&.is-active{
			display: block;
			@include breakpoint(small){
				overflow: hidden;
				padding: rem-calc(100) 0 rem-calc(50);				
			}
			@include breakpoint(large){
				overflow: auto;
				padding: 0;
			}			
		}				
		.nav {
			ul {
				display: flex;
				justify-content: center;
				list-style: none;
				@include breakpoint(small){					
					flex-direction: column;
					margin: 0;
					text-align: right;
				}
				@include breakpoint(large){
					flex-direction: row;
					margin: 0;
					text-align: center;
				}
				li {
					display: inline-block;
					@include breakpoint(small){
						margin: rem-calc(8) rem-calc(16);
					}
					@include breakpoint(large){
						margin:0;
					}
					a, .nav-link {	
						color: $primary-color;
						display: inline-block;	
						font-family: 'rig-shaded-medium-face';								
						font-weight: 400;	
						letter-spacing: rem-calc(1);
						padding: rem-calc(4) 0;
						position: relative;
						text-decoration: none;
						text-transform: uppercase;
						@include breakpoint(small){
							font-size: rem-calc(16);
							line-height: rem-calc(22);
						}
						@include breakpoint(large){													
							font-size: rem-calc(16);
							line-height: rem-calc(20);
							margin: 0 rem-calc(32);
							
						}
						&.nav-internships {
							&:before {
								background-color: $internships;
							}
						}
						&.nav-graduates {
							&:before {
								background-color: $graduates;
							}
						}
						&.nav-apprenticeships {
							&:before {
								background-color: $apprenticeships;
							}
						}
						&.nav-other {
							&:before {
								background-color: $other;
							}
						}
						
						&:before {
							background-color: $primary-color;
							bottom: 0;
							content: '';							
							height: rem-calc(4);
							opacity: 0;
							position: absolute;
							width: 100%;
						}
						&:hover {
							color: $primary-color;
							&:before {
								opacity: 1;
							}
						}
						&.active {		
							font-family: 'rig-shaded-medium-face';	
							font-weight: 600;			
							@include breakpoint(small){		
								color: $primary-color;								
							}
							@include breakpoint(large){
								background-color: $white;
								color: $primary-color;
								text-decoration: none;
							}	
							&:before {
								opacity: 1;	
							}						
							
						}
					}
				}
				&.nav-footer {					
					margin-top: rem-calc(40);
					li {
						margin: rem-calc(2) rem-calc(16);
						a {
							font-family: 'rig-sans';
							font-weight: 400;
							text-transform: none;
						}
					}
				}
			}
		}
		.nav-splatter {
			background-image: url("/assets/images/splatter-graduates.png");
			background-repeat: no-repeat;
			background-size: contain;
      height:100%;
      left: rem-calc(-120);
      position: absolute;
      bottom: 0;      
      transform: rotate(90deg) scaleX(-1);
      width:100%;
      z-index: -1;
			@include breakpoint(small){
				max-height: rem-calc(320);
				max-width: rem-calc(320);
			}	
			@include breakpoint(medium){
				max-height: rem-calc(480);
				max-width: rem-calc(480);
			}	
    }
	}

	.nav-bg {
		background-color: $primary-color;
		display: none;   
    height: 100%;
		left: 0;
    min-height: 100vh;
		opacity: 0.85;
		position: absolute;
		top: 0;
		width: 100%;		
    z-index: 9;
		&.is-active {			
			@include breakpoint(small){
				display: block;
			}
			@include breakpoint(large){
				display: none;
			}
		}
	}

	// nav button - mobile / tablet
	.nav-menu-button{
		@include vertical-center;
		display: flex;
		justify-content: center;
		right: rem-calc(16);
		z-index: 11;
		.hamburger-menu{
			display: inline-block;
			.hamburger {
			  padding: rem-calc(18) rem-calc(8) rem-calc(8) rem-calc(8);
			  display: inline-block;
			  cursor: pointer;
			  transition-property: opacity, filter;
			  transition-duration: 0.15s;
			  transition-timing-function: linear;
			  font: inherit;
			  color: inherit;
			  text-transform: none;
			  background-color: transparent;
			  border: 0;
			  margin: 0;
			  overflow: visible;
			  outline: none!important;
			  .menu-text {
				  display: none;
				  padding-right: rem-calc(10);
			  }
			  .hamburger-box {
				width: rem-calc(40);
				height: rem-calc(26);
				display: inline-block;
				position: relative;
				.hamburger-inner {
				  display: block;
				  top: 50%;
				  margin-top: -2px;
				  width: rem-calc(40);
				  height: rem-calc(4);
				  background-color: $black;
				  border-radius: 4px;
				  position: absolute;
				  transition-property: transform;
				  transition-duration: 0.15s;
				  transition-timing-function: ease;
				  &:before, &:after{
					content: "";
					display: block;
					width: rem-calc(40);
					height: 4px;
					background-color: $black;
					border-radius: 4px;
					position: absolute;
					transition-property: transform;
					transition-duration: 0.15s;
					transition-timing-function: ease;
				  }
				  &:before{
					top: -10px;
				  }
				  &:after{
					bottom: -10px;
				  }
				}		
			  }
			  &.hamburger--elastic{
				.hamburger-inner{
				  top: 2px;
				  transition-duration: 0.275s;
				  transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
				  &:before{
					top: rem-calc(10);
					transition: opacity 0.125s 0.275s ease;
				  }
				  &:after{
					top: rem-calc(20);
					transition: transform 0.275s cubic-bezier(0.68, -0.55, 0.265, 1.55);
				  }
				}
				&.is-active{
					.menu-text {
						color: $black;
					}
				  .hamburger-inner{
					  background-color: $black;
					transform: translate3d(0, 8px, 0) rotate(135deg);
					transition-delay: 0.075s;
					&:before{
						background-color: $black;
					  transition-delay: 0s;
					  opacity: 0;
					}
					&:after{
						background-color: $black;
					  transform: translate3d(0, -20px, 0) rotate(-270deg);
					  transition-delay: 0.075s;
					}
				  }
				}
				&:focus {
					.menu-text {
						color: $black;
					}
					.hamburger-inner {
						background-color: $black;
						&:before, &:after {
							background-color: $black;
						}
					} 
				  }				
			  }
			}			
		  }			
	}	
}

.header-v2, .header-v3 {
	&.header {
		background-color: $white;
		.logo {
			@include breakpoint(large){
				margin-bottom: rem-calc(10);
			}			
		}
		.nav-menu-container {
			background-color: $secondary-color;
			.nav {
				ul {
					justify-content: flex-start;
					li {
						a {
							margin:0;
							&:hover {
								background-color: darken($primary-color, 10%);
								outline: none;
							}
							&.active {
								@include breakpoint(small){
									color: $white;
									text-decoration: underline;
								}
								@include breakpoint(large){
									color: $white;
									background-color: $primary-color;
									outline: none;
									text-decoration: none;
								}							
								
							}
						}
					}
					
				}
			}
		}
	}
}

.header-v3 {
	.dual-logo {
		justify-content: space-between;
		.logo {
			@include breakpoint(small){
				max-width: 33vw;
			}
			@include breakpoint(medium){
				max-width: initial;
			}			
		}		
	}

	.nav-menu-button {
		position: relative;
		right:0;
		top:initial;
		transform: initial;
	}

}
