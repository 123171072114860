footer {
  color: $primary-color;
  position: relative;
  @include breakpoint(small) {
    padding: rem-calc(20) 0;
    text-align: center;
  }
  @include breakpoint(large) {
    text-align: left;
  }
  .grid-relative {
    z-index: 1;
  }
  .social-links {
    display: flex;
    justify-content: space-evenly;
    list-style: none;
    margin: 0 auto;
    max-width: rem-calc(540);
    li {
      a {
        align-items: center;
        background-color: transparent;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        @include breakpoint(small) {
          height: rem-calc(42);
          width: rem-calc(42);
        }
        @include breakpoint(medium) {
          height: rem-calc(57);
          width: rem-calc(57);
        }
        svg {
          @include breakpoint(small) {
            height: rem-calc(42);
            width: rem-calc(42);
          }
          @include breakpoint(medium) {
            height: rem-calc(57);
            width: rem-calc(57);
          }
          .svg-fill {
            fill: $primary-color;
          }
        }
        &:hover {
          svg {
            .svg-fill {
              fill: $black;
            }
          }
        }
        &:focus {
          svg {
            .svg-stroke {
              stroke: $black !important;
              fill: $focus-color !important;
            }
          }
        }
      }
    }
    &.internships {
      li {
        a {
          &:hover {
            svg {
              .svg-fill {
                fill: $internships;
              }
            }
          }
        }
      }
    }
    &.graduates {
      li {
        a {
          &:hover {
            svg {
              .svg-fill {
                fill: $graduates;
              }
            }
          }
        }
      }
    }
    &.apprenticeships {
      li {
        a {
          &:hover {
            svg {
              .svg-fill {
                fill: $apprenticeships;
              }
            }
          }
        }
      }
    }
  }
  .footer-links {
    display: flex;
    justify-content: space-between;
    list-style: none;
    @include breakpoint(small) {
      flex-direction: column;
      margin: rem-calc(30) 0;
    }
    @include breakpoint(medium) {
      flex-direction: row;
    }
    li {
      display: block;
      @include breakpoint(small) {
        margin: rem-calc(8) 0;
      }
      @include breakpoint(medium) {
        margin: 0;
      }
      a {
        color: $primary-color;
        display: inline-block;
        font-size: rem-calc(16);
        line-height: rem-calc(21);
        text-decoration: underline;
      }
    }
  }
}
