// buttons
.btn {
	background-color: $white;
	border: 1px solid $white;
	border-radius: rem-calc(20);
	color: $primary-color;
	cursor: pointer;
	display: inline-block;
	font-family: 'rig-shaded-medium-face', sans-serif;
	font-weight: 400;	
	padding: rem-calc(12) rem-calc(10) rem-calc(7) rem-calc(10);
	text-align: center;
	transition: all 0.25s ease;
	width:100%;
	@include breakpoint(small){
		font-size: rem-calc(16);
		line-height: rem-calc(16);
	}
	@include breakpoint(medium){
		font-size: rem-calc(16);
		line-height: rem-calc(21);
	}	

	&:hover {
		background-color: $primary-color;
		border-color: $primary-color;
		color: $white;		
	}
}

.btn-primary {
	background-color: $primary-color;
	border: 1px solid $primary-color;
	color: $white;	
	&:hover {
		background-color: $white;
		//border-color: $white;
		color: $primary-color;		
	}
}

.btn-alt {
	background-color: $white;
	border: 1px solid $primary-color;
	color: $primary-color;	
	&:hover {
		background-color: $white;
		//border-color: $white;
		color: $primary-color;		
	}
}

.btn-load-more {
	border-color: $secondary-color;
	max-width: rem-calc(170);
	padding: rem-calc(12) rem-calc(10) rem-calc(10) rem-calc(10);
	width:100%;
	@include breakpoint(small){
		font-size: rem-calc(16);
		line-height: rem-calc(16);
	}
	@include breakpoint(medium){
		font-size: rem-calc(12);
		line-height: rem-calc(16);
	}	
}
