// typography

h1,h2,h3,h4,h5,h6{
	font-weight: 700;
}

.light{
	font-weight: 300!important;
}
.regular{
	font-weight: 400!important;
}
.medium{
	font-weight: 500!important;
}
.bold{
	font-weight: 700!important;
}

.underline{
	text-decoration: underline;
}

p, li{
    color: $primary-color;
    @include breakpoint(small){
        font-size: rem-calc(16);
        line-height: rem-calc(24);
    }
    @include breakpoint(large){
        font-size: rem-calc(16);
    }           
	a{
		text-decoration: underline;
	}
}

.content-intro {
    //font-family: 'rig-shaded-light-face';  
    font-weight: 400;
    @include breakpoint(small){
        font-size: rem-calc(20);
        line-height: rem-calc(28);
    }
    @include breakpoint(large){
        font-size: rem-calc(24);
        line-height: rem-calc(32);
    }  
}

.section-title-large {
    font-family: 'rig-shaded-light-face';  
    font-weight: 400;
    text-transform: uppercase;
    @include breakpoint(small){
        font-size: rem-calc(32);
        line-height: rem-calc(32);
    }
    @include breakpoint(medium){              
        font-size: rem-calc(60);        
        line-height: rem-calc(60);
    }
}

.section-title-medium {
    @include breakpoint(small){
        font-size: rem-calc(24);
        line-height: rem-calc(24);
        //margin-bottom: rem-calc(16);
    }
    @include breakpoint(medium){
        font-size: rem-calc(36);
        line-height: rem-calc(40);
        //margin-bottom: rem-calc(16);
    }
}

.section-title-small {
    font-family: 'rig-shaded-medium-face';  
    font-weight: 500;
    @include breakpoint(small){
        font-size: rem-calc(20);
        line-height: rem-calc(24);
    }
    @include breakpoint(medium){
        font-size: rem-calc(20);
        line-height: rem-calc(24);
    }
}